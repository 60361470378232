<template>
  <div>
    <div class="top-blue">
      <div class="top-whirt">
        <div class="title">温度卡余额(元)</div>
        <div class="btn-box">
          <div class="money">
            <span class="one">{{ afterAmount[0] }}</span
            ><span class="two">.{{ afterAmount[1] }}</span>
          </div>
        </div>
        <!-- 补助和充值区分来 -->
        <div class="bz-cz">
          <div class="bz"><span class="text">补助(元)</span><span class="number">{{wdObj.bz_wendu_ft}}</span></div>
          <div class="cz"><span class="text">充值(元)</span><span class="number">{{wdObj.cz_wendu_ft}}</span></div>
        </div>
        <div class="bz-cz-bottom">
          <van-divider />
        </div>
        <!-- 饭团米粒 -->
        <div class="ft-ml">
          <div class="ft"><span class="ft-icon"></span><span class="text">饭团(个)</span><span class="number">{{wdObj.wendu_ft}}</span></div>
          <div class="ml"><span class="ml-icon"></span><span class="text">米粒(个)</span><span class="number">{{wdObj.wendu_ml}}</span></div>
        </div>
        <!--  -->
        <van-button class="goPay" @click="handlerRecharge" v-if="isBtn">去充值</van-button>
        <van-divider />
        <div class="info" @click="clickDetail">温度卡明细</div>
      </div>
      <!--  底部提示  -->
      <div class="hint">
        <div class="hint-left"><van-icon name="warning-o" /></div>
        <div class="mult">
          <span>1、每月只能充值一次；</span>
          <span>2、充值单笔限额1000元。</span>
          <span>3、没绑定餐卡不能充值。</span>
          <span>4、家属账号不能充值。</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetCancaInfo } from "@/api/bill";
export default {
  name: "Pay",
  data() {
    return {
      token: "",
      isBtn: false, //是否开启充值
      isMonth: false, //本月是否充值
      amount: "",
      // 分割后的数组
      afterAmount: ["0", "00"],
      wdObj:{
        bz_wendu_ft:0,
        cz_wendu_ft:0,
        wendu_ft: 0,
        wendu_ml:0
      }
    };
  },
  mounted() {
    // this.token = location.hash.split("=")[1];
    this.token = this.$route.query.token
    // console.log('OK')
    var u = navigator.userAgent;
    var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
      // android
      window.AndroidWebView.titleEvent("温度卡");
    }
    if (isiOS) {
      // IOS
      window.webkit.messageHandlers.titleEvent.postMessage("温度卡");
    }
    this.getAmount();
  },
  methods: {
    //余额查询
    getAmount() {
      let params = {
        token: this.token,
      };
      GetCancaInfo(params).then((res) => {
        let { code, data, message } = res;
        console.log(data);
        // this.$toast(code)
        if (code != 0) return this.$toast(message || "请求错误");
        // let bz_wendu_ft = data.bz_wendu_ft*0.01
        // let cz_wendu_ft = data.cz_wendu_ft*0.01
        let bz_wendu_ft = Math.round((data.bz_wendu_ft)*100)/10000
        let cz_wendu_ft = Math.round((data.cz_wendu_ft)*100)/10000
        this.wdObj = {
          bz_wendu_ft:bz_wendu_ft.toFixed(2),
          cz_wendu_ft:cz_wendu_ft.toFixed(2),
          wendu_ft: data.wendu_ft,
          wendu_ml: data.wendu_ml,
        }
        let fankaML = (data.wendu_ml * 100 * 0.1) / 100;
        console.log(fankaML);
        this.amount = data.wendu_ft * 100 + fankaML;
        this.isBtn = data.is_allow_wendu_recharge == 0 ? false : true;
        // this.isMonth = data.month_wendu_is_recharge == 0 ? false : true;
        this.isMonth = data.month_wendu_is_recharge == 0 ? true : true;
        // this.$toast(this.amount)
        if (String(this.amount).indexOf(".") != -1) {
          this.amount = this.amount.toFixed(2);
          this.afterAmount = String(this.amount).split(".");
        } else {
          this.afterAmount = String(this.amount.toFixed(2)).split(".");
        }
      });
    },
    //充值
    handlerRecharge() {
      // if (this.isMonth) return this.$toast("每月只能充值一次，本月已充值");
      // console.log(1111111)
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        // android
        window.AndroidWebView.titleEvent("充值");
      }
      if (isiOS) {
        // IOS
        window.webkit.messageHandlers.titleEvent.postMessage("充值");
      }

      this.$router.push({
        path: "/wdkPayment",
        query: { token: this.token },
      });
    },
    //查看明细
    clickDetail() {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        // android
        window.AndroidWebView.titleEvent("温度卡明细");
      }
      if (isiOS) {
        // IOS
        window.webkit.messageHandlers.titleEvent.postMessage("温度卡明细");
      }
      this.$router.push({ path: "/wdkDetail", query: { token: this.token } });
    },
    //! iOS调用JS入口
    ocToJs(action, params) {
      // document.getElementById("returnValue").innerHTML = action + '?' + params;
    },
    // andorid
    androidCallJS(val) {
      // document.getElementById("anzhuos").innerHTML = val;
    },
  },
};
</script>

<style lang="scss" scoped>
  @mixin bg-image($url) {
    background-image: url($url + "@1x.png");
    @media (-webkit-min-device-pixel-ratio: 2),(min-device-pixel-ratio: 2) {
      background-image: url($url + "@2x.png")
    }
    @media (-webkit-min-device-pixel-ratio: 3),(min-device-pixel-ratio: 3) {
      background-image: url($url + "@3x.png")
    }
  }
.top-blue {
  position: relative;
  width: 100%;
  height: 258px;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  background-color: #02b2ff;
  .top-whirt {
    width: 343px;
    height: 469px;
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    // padding: 16px 16px 0;
    box-sizing: border-box;
    .title {
      height: 14px;
      font-size: 14px;
      font-weight: 400;
      color: #60606a;
      line-height: 14px;
      padding: 45px 0 20px;
      text-align: center;
    }
    .btn-box {
      display: flex;
      justify-content: space-between;
      .money {
        display: flex;
        align-items: center;
        margin: 0 auto 30px;
        .one {
          font-size: 46px;
          font-weight: bold;
          color: #181830;
          // line-height: 24px;
        }
        .two {
          font-size: 36px;
          font-weight: bold;
          color: #181830;
          // line-height: 18px;
          padding-top: 4px;
        }
      }
    }
    .bz-cz{
      display: flex;
      justify-content: space-around;
      padding-bottom: 24px;
      margin: 0 24px 16px;
      .bz,,.cz{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 400;
        color: #60606A;
        line-height: 12px;
        .text {
          font-size: 12px;
          margin: 8px 0;
          color: #60606A;
        }
        .number {
          font-size: 13px;
          color: #000000;
        }
      }
    }

    .ft-ml {
      display: flex;
      justify-content: space-around;
      margin-bottom: 28px;
      .ft,.ml {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: #60606A;
        line-height: 12px;
        .text {
          margin: 8px 0;
        }
        .number {
          color: #373737;
        }
      }
      .ft-icon,.ml-icon {
        width: 36px;
        height: 36px;
        display: block;
        // border: 1px dashed #666;
      }
      .ft-icon {
          @include bg-image('../../../assets/images/ft');
          background-size: 36px 36px;
          background-repeat: no-repeat;
      }
      .ml-icon {
          @include bg-image('../../../assets/images/ml');
          background-size: 36px 36px;
          background-repeat: no-repeat;
      }
    }
      .goPay {
        width: 267px;
        height: 44px;
        background: linear-gradient(90deg, #05d9ff 0%, #02b2ff 100%);
        border-radius: 22px;
        color: #fff;
        font-size: 14px;
        display: block;
        margin: 0 auto;
      }
    .info {
      text-align: center;
      font-size: 14px;
      color: #02b2ff;
    }
  }
}

.van-divider{
  border-color:#EDEFF3
}
.bz-cz-bottom .van-divider{
  border-color: #E9E9E9;
  margin: 18px 24px;
}
.test {
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
}
.anzhuo {
  font-size: 30px;
  position: absolute;
  top: 60%;
  left: 50%;
}
.hint {
  position: absolute;
  display: flex;
  top: 500px;
  left: 20px;
  color: #7f7f7f;
  .van-icon {
    font-size: 14px;
    margin-right: 5px;
    color: #ff8420;
    padding-top: 4px;
  }
  .mult {
    display: flex;
    flex-direction: column;
    line-height: 22px;
    padding-left: 5px;
  }
}
</style>